<template>
	<div class="mt-2 flex justify-between items-center" v-if="section.buttons?.bl || section.buttons?.br">
		<div class="block">
			<button v-if="section.buttons?.bl" :class="section.buttons.bl.class" @click="section.buttons.bl.click()">
				{{ section.buttons.bl.label }}
			</button>
		</div>
		<div class="block">
			<button v-if="section.buttons?.br" :class="section.buttons.br.class" @click="section.buttons.br.click()">
				{{ section.buttons.br.label }}
			</button>
		</div>
	</div>
</template>

<script setup>
import { inject } from 'vue'
const props = defineProps({
	section: {
		type: Object,
	},
})

const rysqer = inject('rysqer')
</script>
