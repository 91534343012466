<template>
	<div>
		<div v-if="!loading">
			<Vueform
				ref="refForm"
				v-bind="definition"
				v-model="form"
				sync
				:disabled="disabled"
				@submit="onSubmit"
				:endpoint="false"
			>
			</Vueform>
		</div>
		<div class="flex justify-center" v-else>
			<span class="text-center d-loading text-primary"></span>
		</div>
	</div>
</template>

<script setup>
import { ref, watch, computed, watchEffect, onMounted, onUnmounted } from 'vue'
import axios from '@/libs/axios'
import jsonLogic from 'json-logic-js'
import transformJS from 'js-to-json-logic' //https://github.com/krismuniz/js-to-json-logic

const props = defineProps({
	definitionEndpoint: {
		type: String,
	},
	valuesEndpoint: {
		type: String,
	},
	createOnly: {
		type: Boolean,
		default: false,
	},
	submitOnStep: {
		type: Boolean,
		default: false,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
})

const emit = defineEmits(['submit', 'success', 'beforeCreate', 'mounted'])

const definition = ref()
const callbacks = ref()
const refForm = ref(null)
const form = ref({})
const busy = ref()
const loading = ref()

watch(
	() => props.definitionEndpoint,
	() => {
		loading.value = true
		Promise.all([
			axios.get(props.definitionEndpoint).then(({ data }) => {
				definition.value = data.definition
				callbacks.value = data.callbacks
			}),

			!props.createOnly
				? axios.get(props.valuesEndpoint).then(({ data }) => {
						form.value = data
				  })
				: null,
		])
			.catch((error) => console.log(error))
			.finally(() => {
				loading.value = false

				/* definition.value.schema.measures.conditions = [
					(form$, el$) => {
						let status = form$.el$('status')?.value
						if (!status) status = 'open'

						let a = ['open', 'all'].includes(status)
						let b = el$.value.some((e) => e.state == 'open')
						return a && b
					},
				] */
			})
	},
	{ immediate: true }
)

const onNextPage = () => {
	const next = refForm.value.steps$.next$ ? refForm.value.steps$.next$.label : refForm.value.steps$.last$.label
	console.log('Running callbacks for page: ' + next)

	let pageCallbacks = callbacks.value.find((element) => element.toPage === next)

	if (pageCallbacks && pageCallbacks.callbacks && pageCallbacks.callbacks.length > 0) {
		for (let i = 0; i < pageCallbacks.callbacks.length; i++) {
			let callback = pageCallbacks.callbacks[i]
			if (!callback.rule || (callback.rule && jsonLogic.apply(callback.rule, form.value))) {
				if (callback.callback in callbackMethods) {
					callbackMethods[callback.callback](...callback.params)
					if (callback.stopIfTrue) {
						console.log('Stopping processing callbacks because of flag')
						break
					}
				}
			}
		}
	}

	if (props.submitOnStep) {
		const current = refForm.value.steps$.current$.label
		axios.post(props.valuesEndpoint, { step: current, ...form.value })
	}
}

watchEffect(() => {
	console.log('Running watch effect...')
	if (refForm.value && refForm.value.steps$) {
		refForm.value.steps$.on('next', () => {
			onNextPage()
		})
	}
})

const onSubmit = () => {
	axios
		.post(props.valuesEndpoint, {
			step: refForm.value.steps$ ? refForm.value.steps$.last$.label : null,
			...form.value,
		})
		.then(() => {
			emit('success', form.value)
		})
		.catch((error) => {
			emit('eror', error)
		})

	emit('submit', form.value)
}

const reset = () => {
	refForm.value.reset()
}

const clear = () => {
	refForm.value.clear()
}

// ------------------------------------------------
// Callback definitions
// ------------------------------------------------
const removeElementItem = (element, value) => {
	console.log('Remove ' + value + ' on ' + element)
	let items = definition.value.schema[element].items
	let finalItems = _.filter(items, (o) => o.value !== value)

	definition.value.schema[element].items = finalItems
}

const removeElementItems = (element, items) => {
	items.forEach((item) => {
		removeElementItem(element, item)
	})
}

const alignElementItems = (fromScale, toScale) => {
	console.log('Align element item values' + fromScale + ' -> ' + toScale)
	let schemaOptions = definition.value.schema[fromScale].items
	let formValue = _.get(form.value, fromScale)
	let filteredSchemaOptions = _.filter(schemaOptions, (o) => o.value <= formValue)

	definition.value.schema[toScale].items = filteredSchemaOptions
}

const maxElementItems = (fromArr, toArr) => {
	console.log('Max  element items')
	let schema = definition.value.schema

	for (let i = 0; i < fromArr.length; i++) {
		let fromItems = schema[fromArr[i]].items
		let formValue = _.get(form.value, fromArr[i])
		let filtered = fromItems.filter((f) => f.value <= formValue)
		schema[toArr[i]].items = filtered
	}

	definition.value.schema = schema
}

const disableElement = (element) => {
	// attribute disabled: false must be set on element, otherwise it is not working
	console.log('Disable element: ' + element)
	definition.value.schema[element].disabled = true
}

const disableElements = (elements) => {
	// attribute disabled: false must be set on element, otherwise it is not working
	elements.forEach((e) => {
		disableElement(e)
	})
}

const setFormValue = (item, value) => {
	console.log('Set form value ' + item + ' to ' + value)
	_.set(form.value, item, value)
}

const resetFormValue = (item) => {
	console.log('Reset form value: ' + item)
	_.set(form.value, item, null)
}

const resetFormValues = (items) => {
	items.forEach((item) => {
		resetFormValue(item)
	})
}

const transferFormValue = (fromItem, toItem) => {
	console.log('Transer form value from' + fromItem + ' to ' + toItem)
	_.set(form.value, toItem, _.get(form.value, fromItem))
}

const transferFormValues = (fromToItemsArray) => {
	fromToItemsArray.forEach((ftItem) => {
		transferFormValue(ftItem[0], ftItem[1])
	})
}

const callbackMethods = {
	removeElementItem,
	removeElementItems,
	alignElementItems,
	maxElementItems,
	disableElement,
	disableElements,
	setFormValue,
	resetFormValue,
	resetFormValues,
	transferFormValue,
	transferFormValues,
}

defineExpose({ form, refForm, definition, transformJS, callbackMethods, reset, clear })
</script>

<style lang="scss">
.vf-edit-button input[type='checkbox'] {
	display: block;
}

.vf-view-disabled {
	.form-bg-disabled {
		@apply bg-transparent mx-0 text-gray-800;
	}
	.form-pl-input-lg {
		@apply pl-0;
	}
	.form-border-color-input {
		@apply border-none;
	}
}

.vf-readonly-textarea,
.vf-readonly-textinput {
	@apply cursor-pointer;
	.form-p-input-lg,
	.form-p-input-sm {
		@apply p-0 bg-transparent;
	}
	.form-radius-large-lg,
	.form-radius-large-sm {
		@apply border-none outline-none;
	}
	.form-radius-input-lg,
	.form-radius-input-sm {
		@apply border-none outline-none;
	}
}
</style>
