<template>
	<div :class="section.class" v-if="section.visible">
		<section-top :section="section" />
		<component
			:is="section.component"
			:stage="stage"
			:view="view"
			:scene="scene"
			:section="section"
			:options="section.options"
			:resources="resources"
			:ref="(el) => (section.ref = el)"
		/>
		<section-bottom :section="section" />
	</div>
</template>

<script setup>
import { ref, inject } from 'vue'

import SectionTop from './Top'
import SectionBottom from './Bottom'

const props = defineProps({
	stage: {
		type: Object,
	},
	view: {
		type: Object,
	},
	scene: {
		type: Object,
	},
	section: {
		type: Object,
	},
	resources: {
		type: Object,
	},
})

const rysqer = inject('rysqer')
</script>
