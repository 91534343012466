import { TDialog } from 'vue-tailwind/dist/components'

export default {
	component: TDialog,
	props: {
		fixedClasses: {
			overlay:
				'overflow-auto scrolling-touch absolute left-0 top-0 bottom-0 right-0 w-full h-full fixed flex flex-col justify-center items-center',
			wrapper: 'relative',
			modal: 'overflow-visible relative',
			close: 'flex items-center justify-center',
			dialog: 'overflow-visible relative',
		},
		classes: {
			close:
				'bg-gray-100 text-gray-600 rounded-full absolute right-0 top-0 -m-3 h-8 w-8 transition duration-100 ease-in-out hover:bg-gray-200 focus:ring-2 focus:ring-indigo-600 focus:outline-none focus:ring-opacity-50',
			closeIcon: 'fill-current h-4 w-4',

			overlay: 'z-40 bg-black bg-opacity-30',
			wrapper: 'z-50 min-w-lg max-w-lg px-3 py-12',
			dialog: 'bg-white shadow-xl rounded-xl text-left',

			body: 'p-4 space-y-3',
			buttons: 'p-4 flex space-x-4 justify-center bg-white rounded-b-xl',

			iconWrapper: 'bg-gray-100 flex flex-shrink-0 h-12 items-center justify-center rounded-full w-12 mx-auto',
			icon: 'w-6 h-6 text-gray-500',
			content: 'w-full flex justify-center flex-col',

			titleWrapper: '',
			title: 'text-lg font-semibold text-center',

			textWrapper: 'text-center py-4 w-full',
			text: '',

			cancelButton: 'flex-1 d-btn d-btn-block',
			okButton: 'flex-1 d-btn d-btn-primary d-btn-block',

			inputWrapper: 'mt-3 flex items-center space-x-3',

			input:
				'block w-full px-3 py-2 text-black placeholder-gray-400 transition duration-100 ease-in-out bg-white border border-gray-300 rounded focus:border-indigo-600 focus:ring-2 focus:ring-indigo-600 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed w-full',
			select:
				'block w-full px-3 py-2 text-black placeholder-gray-400 transition duration-100 ease-in-out bg-white border border-gray-300 rounded focus:border-indigo-600 focus:ring-2 focus:ring-indigo-600 focus:outline-none focus:ring-opacity-50  disabled:opacity-50 disabled:cursor-not-allowed w-full',

			radioWrapper: 'flex items-center space-x-2',
			radio:
				'text-indigo-600 transition duration-100 ease-in-out border-gray-300 shadow-sm focus:border-indigo-600 focus:ring-2 focus:ring-indigo-600 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed',
			radioText: '',

			checkboxWrapper: 'flex items-center space-x-2',
			checkbox:
				'text-indigo-600 transition duration-100 ease-in-out border-gray-300 rounded shadow-sm focus:border-indigo-600 focus:ring-2 focus:ring-indigo-600 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed',
			checkboxText: '',

			errorMessage: 'text-red-500 block text-sm',

			busyWrapper: 'absolute bg-opacity-50 bg-white flex h-full items-center justify-center left-0 top-0 w-full',
			busyIcon: 'animate-spin h-6 w-6 fill-current text-gray-500',

			overlayEnterClass: 'opacity-0',
			overlayEnterActiveClass: 'transition ease-out duration-100',
			overlayEnterToClass: 'opacity-100',
			overlayLeaveClass: 'opacity-100',
			overlayLeaveActiveClass: 'transition ease-in duration-75',
			overlayLeaveToClass: 'opacity-0',

			enterClass: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
			enterActiveClass: 'ease-out duration-300',
			enterToClass: 'opacity-100 translate-y-0 sm:scale-100',
			leaveClass: 'opacity-100 translate-y-0 sm:scale-100',
			leaveActiveClass: 'ease-in duration-200',
			leaveToClass: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
		},
		variants: {
			danger: {
				okButton: 'flex-1 d-btn d-btn-error d-btn-block',
			},
			options: {
				okButton: 'flex-1 d-btn d-btn-primary d-btn-block',
				cancelButton: 'flex-1 d-btn d-btn-primary d-btn-block',
			},
		},
	},
}
