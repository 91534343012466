<template>
	<div>
		<portal :to="portal" :disabled="portal == null">
			<input type="text" class="w-full d-input d-input-bordered d-input-sm" v-model="search" placeholder="Search..." />
		</portal>
		<slot :search="search"></slot>
	</div>
</template>

<script setup>
import { ref, watch } from 'vue'

const props = defineProps({
	portal: {
		type: String, // name of portal to transport search field to
		default: null,
	},
	containerClass: {
		type: String,
	},
})

const search = ref()
</script>
