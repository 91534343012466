import axios from 'axios'
import router from '@/router'
import { useAuthStore } from '@/stores/auth'

// let sourceRequest = {}

const client = axios.create({
	baseURL: process.env.VUE_APP_API_BASE_URL,
	withCredentials: true,
})

client.CancelToken = axios.CancelToken

client.interceptors.request.use(
	(config) => {
		config.headers['X-Socket-ID'] = window.Echo.socketId()
		return config
	}
	// (request) => {
	// 	if (sourceRequest[request.url]) {
	// 		sourceRequest[request.url].cancel('Automatic cancellation')
	// 	}

	// 	const axiosSource = axios.CancelToken.source()
	// 	sourceRequest[request.url] = { cancel: axiosSource.cancel }
	// 	request.cancelToken = axiosSource.token

	// 	return request
	// },
	// (error) => {
	// 	return Promise.reject(error)
	// }
)

client.interceptors.response.use(
	(response) => {
		return response
	},
	(error) => {
		if (error.response && [401, 403, 419].includes(error.response.status)) {
			useAuthStore().resetUser()
			router.push({ name: 'login' }).catch(() => {})
		}

		return Promise.reject(error)
	}
)

export default client
