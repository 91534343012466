<template>
	<div
		ref="refListItem"
		class="flex justify-between items-center gap-4"
		:class="[
			{
				'bg-base-200': selected,
				'hover:bg-base-200': !selected,
			},
			containerClass,
		]"
		@click="$emit('click', emits)"
	>
		<div class="flex min-w-0">
			<div class="flex items-center gap-4">
				<div class="min-w-3 text-center">
					<div class="rounded-full w-2 h-2 bg-primary" v-if="unread"></div>
				</div>
				<p class="rysqer-identifier" :class="[symbol?.class]" v-if="symbol?.text || symbol?.icon">
					<span v-if="symbol.text">{{ symbol.text }}</span>
					<icon :icon="symbol.icon" cls="h-4 w-4 text-inherit" v-if="symbol.icon"></icon>
				</p>
				<div>
					<p class="text-sm line-clamp-1">
						<template v-for="(identifier, index) in identifiers">
							<span :key="index" :class="{ 'font-bold': index == 0, 'pl-1': index > 0 }">
								{{ identifier }}
							</span>
						</template>
					</p>

					<p class="text-sm line-clamp-1 select-none" v-if="width > smallBreakpoint && name">{{ name }}</p>
					<p class="pt-1 opacity-80 text-xs" v-if="sent_at">{{ sent_at | dateTime }}</p>
				</div>
			</div>
		</div>
		<div class="flex-none">
			<div class="flex justify-end items-center gap-3">
				<div v-if="!onSmallDiv && badges && badges.length" class="flex items-center gap-2">
					<div
						v-for="(badge, index) in badges.filter((b) => b.condition == null || b.condition == true)"
						:key="index"
						:class="badge.class"
					>
						<tippy v-if="badge.tooltip" trigger="mouseenter focus" theme="dark" :arrow="true">
							<template #trigger>
								<div>
									<icon :icon="badge.icon" :cls="badge.iconClass ?? 'h-4 w-4 text-inherit'" v-if="badge.icon"></icon>
									<span v-else>{{ badge.name }}</span>
								</div>
							</template>
							<div class="p-1">
								<p>{{ badge.tooltip }}</p>
							</div>
						</tippy>

						<div v-else>
							<icon :icon="badge.icon" :cls="badge.iconClass ?? 'h-4 w-4 text-inherit'" v-if="badge.icon"></icon>
							<span v-else>{{ badge.name }}</span>
						</div>

						<!-- 
						<div class="realtive d-tooltip" :data-tip="badge.tooltip ?? null">
							<icon :icon="badge.icon" :cls="badge.iconClass ?? 'h-4 w-4 text-inherit'" v-if="badge.icon"></icon>
							<span v-else>{{ badge.name }}</span>
						</div> -->
					</div>
				</div>

				<country-flag :country="locale" size="small" rounded v-if="!onSmallDiv && locale"></country-flag>

				<div class="rounded-full w-6 h-6 flex justify-center items-center p-1">
					<icon icon="chevron-right"></icon>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useElementSize } from '@vueuse/core'

const props = defineProps({
	emits: {
		type: [Object, String],
		required: true,
	},
	unread: {
		type: Boolean,
		default: false,
	},
	sent_at: {
		type: String,
	},
	symbol: {
		type: Object,
		default: () => {
			return {
				text: null,
				class: null,
				icon: null,
			}
		},
	},
	identifiers: {
		type: Array,
		default: () => {
			return []
		},
	},
	name: {
		type: String,
	},
	locale: {
		type: String,
	},

	avatar: {
		type: Object,
	},
	badges: {
		type: [Array, Object],
	},

	deactivated: {
		type: [Boolean, Number],
		default: false,
	},
	selected: {
		type: [Boolean, Number],
	},
	containerClass: {
		type: String,
		default: 'p-3 border-b',
	},
})

const refListItem = ref(null)

const smallBreakpoint = 300

const { width, height } = useElementSize(refListItem)

const onSmallDiv = computed(() => {
	return width.value <= smallBreakpoint
})
</script>
