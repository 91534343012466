import { defineStore } from 'pinia'
import moment from 'moment'

export const useFormattersStore = defineStore('rysqer-formatters-store', () => {
	const dateTime = (value) => {
		return moment(value).format('MMM DD, YY hh:mm a')
	}

	const date = (value) => {
		return moment(value).format('MMM DD, YY')
	}

	const upperFirst = (value) => {
		return _.upperFirst(value)
	}

	const todaysTimeOrDate = (value) => {
		if (moment(value).isBefore(moment.now())) {
			return moment(value).format('MMM DD, YY')
		} else {
			return moment(value).format('hh:mm a')
		}
	}

	const htmlToText = (value) => {
		return value.replace(/<[^>]+>/g, '')
	}

	return {
		dateTime,
		date,
		upperFirst,
		todaysTimeOrDate,
		htmlToText,
	}
})
