<template>
	<div>
		<input type="checkbox" class="d-modal-toggle" v-model="section.visible" />
		<div class="d-modal overflow-hidden">
			<div class="d-modal-box p-0 flex flex-col">
				<div class="flex-none sticky top-0 z-10 border-b bg-white">
					<div class="flex justify-between items-center px-6 py-4">
						<p class="text-xl font-bold">{{ section.label }}</p>
						<button class="d-btn d-btn-ghost d-btn-square d-btn-sm" @click="section.visible = false">
							<icon icon="close"></icon>
						</button>
					</div>

					<portal-target name="modal"></portal-target>
				</div>

				<div class="flex-1 p-4 overflow-y-auto" :class="section.class">
					<component
						v-if="section.visible"
						:is="section.component"
						:stage="stage"
						:view="view"
						:scene="scene"
						:section="section"
						:options="section.options"
						:resources="resources"
						:ref="(el) => (section.ref = el)"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, inject } from 'vue'

const props = defineProps({
	stage: {
		type: Object,
	},
	view: {
		type: Object,
	},
	scene: {
		type: Object,
	},
	section: {
		type: Object,
	},
	resources: {
		type: Object,
	},
})

const rysqer = inject('rysqer')
</script>
