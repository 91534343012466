<template>
	<div class="flex justify-between items-center gap-2 mb-6" v-if="section.label || section.buttons?.tr">
		<div class="flex items-center gap-2" v-if="!section.options?.collapsible">
			<div class="rysqer-identifier" :class="section?.options?.identifier?.color" v-if="section?.options?.identifier">
				{{ section?.options?.identifier?.label }}
			</div>
			<p class="text-lg font-bold">{{ section.label }}</p>
		</div>
		<div class="block" v-else></div>

		<button v-if="section.buttons?.tr" :class="section.buttons.tr.class" @click="section.buttons.tr.click()">
			{{ section.buttons.tr.label }}
		</button>
	</div>
</template>

<script setup>
import { inject } from 'vue'

const props = defineProps({
	section: {
		type: Object,
	},
})

const rysqer = inject('rysqer')
</script>
