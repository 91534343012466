import en from '@vueform/vueform/locales/en'
import tailwind from '@vueform/vueform/dist/tailwind.vue2'
import { defineConfig } from '@vueform/vueform'
import axios from '@/libs/axios'

export default defineConfig({
	theme: tailwind,
	locales: { en },
	locale: 'en',
	axios,
})
