<template>
	<section-collapsible
		v-if="section?.options?.collapsible"
		:stage="stage"
		:view="view"
		:scene="scene"
		:section="section"
		:resources="resources"
	></section-collapsible>

	<section-modal
		v-else-if="section?.options?.modal"
		:stage="stage"
		:view="view"
		:scene="scene"
		:section="section"
		:resources="resources"
	></section-modal>

	<section-standard
		v-else
		:stage="stage"
		:view="view"
		:scene="scene"
		:section="section"
		:resources="resources"
	></section-standard>
</template>

<script setup>
import { ref, inject } from 'vue'

import SectionCollapsible from './Collapsible'
import SectionModal from './Modal'
import SectionStandard from './Standard'

const props = defineProps({
	stage: {
		type: Object,
	},
	view: {
		type: Object,
	},
	scene: {
		type: Object,
	},
	section: {
		type: Object,
	},
	resources: {
		type: Object,
	},
})

const rysqer = inject('rysqer')
</script>
